import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export class TermTypes extends Vue {
  public setTermTypeText(number, type) {
    if (number > 1) {
      return this.$tc("Terms.Type." + type.toLowerCase()).toLowerCase();
    } else {
      return this.$tc(
        "Terms.Type." + type.substring(0, type.length - 1).toLowerCase()
      ).toLowerCase();
    }
  }
}
